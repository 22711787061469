<template>
  <div class="pa-4">
    <v-row>
      <v-col class="pt-2 pb-6">
        <h2>{{ $t('Basic information') }}</h2>
      </v-col>
    </v-row>
    <v-form v-model="validForm">
      <v-row>
        <v-col lg="6">
          <v-text-field
            v-model="client"
            :label="$t('Client name')"
            filled
            required
            :rules="$rules.required"
          ></v-text-field>
        </v-col>

        <v-col lg="6">
          <v-text-field
            v-model="name"
            :label="$t('Campaign name')"
            filled
            required
            :rules="$rules.required"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!inModal">
        <v-col lg="6">
          <v-select
            v-model="type"
            :items="campaignTypes"
            :label="$t('Campaign type')"
            disabled
            filled
          ></v-select>
        </v-col>
        <v-col lg="6">
          <p>
            {{
              $t(
                'Switch In campaigns show the advertisement when the watcher is switching the channel.'
              )
            }}
          </p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import FormMixin from '@/mixins/FormMixin'

export default {
  mixins: [FormMixin],
  props: {
    inModal: Boolean
  },
  data: () => ({
    validForm: false,
    campaignTypes: [{ text: 'Switch In', value: 'switch-in' }]
  }),
  computed: {
    name: {
      get() {
        return this.$store.state.campaigns.editor.name
      },
      set(value) {
        this.$store.commit('campaigns/set', ['editor.name', value])
      }
    },
    client: {
      get() {
        return this.$store.state.campaigns.editor.client
      },
      set(value) {
        this.$store.commit('campaigns/set', ['editor.client', value])
      }
    },
    type: {
      get() {
        return this.$store.state.campaigns.editor.type
      },
      set(value) {
        this.$store.commit('campaigns/set', ['editor.type', value])
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit('is-valid', value)
    }
  }
}
</script>
